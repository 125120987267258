import styled from "styled-components";
import { Wrapper as _Wrapper, WrapperColored as _WrapperColored } from "../global";

export const Wrapper = styled(_Wrapper)`
  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 32px;
  }
`;
export const WrapperColored = styled(_WrapperColored)`
  color: white;
`;

export const WrapperColoredPadded = styled(WrapperColored)`
  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 16px;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
  }
`;
export const GridItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 0 16px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    padding: 0;
  }
  & img {
    max-height: 400px;
    width: 100%;
  }
  & p,
  & h4 {
    padding-bottom: 8px;
    @media (max-width: ${props => props.theme.screen.sm}) {
      max-width: 100%;
    }
  }
`;
export const Link = styled.a`
  color: ${props => props.theme.color.accent};
`;
export const LinkBlue = styled.a`
  color: ${props => props.theme.color.secondary};
`;
