import React from "react";
import styled from "styled-components";

const Video = ({ src, title }) => {
  let srcUrl = src;
  if (srcUrl.indexOf("youtube") >= 0) {
    if (srcUrl.indexOf("?") === -1) {
      srcUrl += "?rel=0";
    } else {
      srcUrl += "&rel=0";
    }
  }
  return (
    <Container>
      <VideoContainer>
        <iframe
          src={srcUrl}
          title={title}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          loading="lazy"
        />
      </VideoContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  > iframe {
    width: 100%;
    height: 100%;
  }
`;

export default Video;

export const HostedVideo = ({ src, ...props }) => (
  <InternalVideo>
    <video {...props.width} controls muted={true} autoPlay="autoplay" loop={true} playsInline="" preload="auto">
      <source src={src} type="video/mp4" />
    </video>
  </InternalVideo>
);

const InternalVideo = styled.div`
  display: inherit;
  width: 100%;

  @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }
`;
