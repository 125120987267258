import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  max-width: 800px;
  width: 800px;
  margin: 0 auto;

  padding-top: 90px;
  padding-bottom: 90px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (min-width: ${props => props.theme.screen.sm}) {
    max-width: 95%;
  }
`;
export const ContainerColored = styled(Container)`
  background-color: transparent;
  color: white;
`;

export const Title = styled(Typography)({ fontWeight: 500, paddingBottom: 30, textAlign: "center" });
export const Paragraph = styled(Typography)({ paddingBottom: 20, fontSize: "1.1rem" });
